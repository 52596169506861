@import '/src/assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  DEFAULT MEASUREMENTS STYLES */

// font size
.font-size-0 {
  font-size: rem(0);
}
.font-size-10 {
  font-size: rem(10);
}
.font-size-12 {
  font-size: rem(12);
}
.font-size-14 {
  font-size: rem(14);
}
.font-size-16 {
  font-size: rem(16);
}
.font-size-18 {
  font-size: rem(18);
}
.font-size-20 {
  font-size: rem(20);
}
.font-size-24 {
  font-size: rem(24);
}
.font-size-26 {
  font-size: rem(26);
}
.font-size-30 {
  font-size: rem(30);
}
.font-size-36 {
  font-size: rem(36);
}
.font-size-40 {
  font-size: rem(40);
}
.font-size-50 {
  font-size: rem(50);
}
.font-size-60 {
  font-size: rem(60);
}
.font-size-70 {
  font-size: rem(70);
}
.font-size-80 {
  font-size: rem(80);
}

// quick height
.height-100 {
  height: rem(100);
}
.height-200 {
  height: rem(200);
}
.height-300 {
  height: rem(300);
}
.height-400 {
  height: rem(400);
}
.height-500 {
  height: rem(500);
}
.height-600 {
  height: rem(600);
}
.height-700 {
  height: rem(700);
}

// quick min-height
.min-height-100 {
  min-height: rem(100);
}
.min-height-200 {
  min-height: rem(200);
}
.min-height-300 {
  min-height: rem(300);
}
.min-height-400 {
  min-height: rem(400);
}
.min-height-500 {
  min-height: rem(500);
}
.min-height-600 {
  min-height: rem(600);
}
.min-height-700 {
  min-height: rem(700);
}

// quick width
.width-50 {
  width: rem(50);
}
.width-75 {
  width: rem(75);
}
.width-100 {
  width: rem(100);
}
.width-150 {
  width: rem(150);
}
.width-200 {
  width: rem(200);
}
.width-300 {
  width: rem(300);
}
.width-400 {
  width: rem(400);
}
.width-500 {
  width: rem(500);
}
.width-600 {
  width: rem(600);
}
.width-700 {
  width: rem(700);
}
.width-30p {
  width: 30%;
}
.width-50p {
  width: 50%;
}
.width-60p {
  width: 60%;
}
.width-100p {
  width: 100%;
}

// quick max-width
.max-width-50 {
  max-width: rem(50);
}
.max-width-75 {
  max-width: rem(75);
}
.max-width-100 {
  max-width: rem(100);
}
.max-width-200 {
  max-width: rem(200);
}
.max-width-300 {
  max-width: rem(300);
}
.max-width-400 {
  max-width: rem(400);
}
.max-width-500 {
  max-width: rem(500);
}
.max-width-600 {
  max-width: rem(600);
}
.max-width-700 {
  max-width: rem(700);
}
.max-width-30p {
  max-width: 30%;
}
.max-width-50p {
  max-width: 50%;
}
.max-width-60p {
  max-width: 60%;
}
.max-width-100p {
  max-width: 100% !important;
}
