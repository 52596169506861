.row {
    margin-left: -1.15rem !important;
    margin-right: -1.15rem !important;
}
.primary {
    color: #4a7ba4;
}
.secondary {
    color: #b2b291;
}

h1, h2, h3,
.h1, .h2, .h3 {
    color: #4a7ba4;
}

h4, h5, h6,
.h4, .h5, .h6 {
    color: #b2b291;
}

.addonAfter, .addonBefore {
    color: rgba(0,0,0,0.25);
}
.addonAfter i, .addonBefore i {
    color: #b2b291;
}

.ant-btn-primary {
    background-color: #4a7ba4;
    border-color: #4a7ba4;
}

.ant-tabs-nav .ant-tabs-tab:hover {
    color: #4a7ba4 !important;
}
.ant-tabs-nav .ant-tabs-tab-active {
    color: #4a7ba4 !important;
}
.ant-switch-checked {
    background-color: #4a7ba4;
}
.ant-switch.costSwitch {
    background-color: #b2b291;
}
.ant-switch-checked.costSwitch {
    background-color: #4a7ba4;
}
.ant-input-affix-wrapper .ant-input-prefix, .ant-input-affix-wrapper .ant-input-suffix {
    color: #b2b291 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #4a7ba4;
}
.ant-menu-item-selected > a, .ant-menu-light .ant-menu-item-selected .menu-icon {
    color: #ffffff !important;
}
.ant-menu-item-selected > a:hover, .ant-menu-light .ant-menu-item-selected:hover .menu-icon {
    color: #cdcdcd !important;
}
.ant-menu-inline-collapsed.ant-menu-vertical > .ant-menu-item {
    height: 64px;
    line-height: 64px;
}
.ant-menu-inline-collapsed .menu-icon {
    right: 30px;
    font-size: 80px;
    width: 51px;
    top: 0;
}

/* .icon-vehicleselection.menu-icon {
    padding-top: 8px;
} */

.ivycon-ownvsrentvehicle.menu-icon {
    margin-top: -10px !important;
}

.ant-menu-inline .menu-icon {
    right: 25px;
    width: 20px;
    top: 2px;
    font-size: 45px;
}

.ant-table-header-column .ant-table-column-title {
    font-size:10px;
    font-weight: 600;
}
.ant-table-thead > tr > th .ant-table-header-column {
    color: #4a7ba4;
}

.marginBottomSm {
    margin-bottom: 3px !important;
}
.ant-form label {
    font-size: 0.9rem !important;
    line-height: 1rem !important;
    color: #b2b291 !important;
}
.ant-form-item-label {
    line-height: 1rem !important;
}
.ant-form-item {
    margin-bottom: 7px !important;
}
.ant-form-item-control {
    margin-top: -5px;
}

.ant-table table {
    text-align: center !important;
}
.ant-table-thead > tr > th {
    text-align: inherit !important;
}
.ant-table-header-column .ant-table-column-title {
    font-size: 12px;
}
tr.ant-table-row.ant-table-row-level-0 td:first-child {
    font-weight: 600;
    color: #b2b291;
}
body .ant-back-top {
    bottom: 2rem !important;
}
.warning { color: #fd7e14; }
.smallerText {font-size: smaller;}


/* Use the .k-pdf-export class to style the exported pdf */
.k-pdf-export p, .k-pdf-export .text-dark span {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}
.k-pdf-export h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}
.k-pdf-export .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
}
.k-pdf-export tr.ant-table-row.ant-table-row-level-0 td:first-child {
    border-left: 1px solid #e8e8e8;
}
.k-pdf-export #tempPdf{
    position: relative !important;
    left: inherit !important;
}

/* tablets */
@media only screen and (max-width: 992px) {
    .h1, h1 {
        font-size: 2rem;
    }
    .h3, h3 {
        font-size: 1.6rem;
    }
}

/* smart phones */
@media only screen and (max-width: 768px) {
    .h1, h1 {
        font-size: 1.5rem;
    }
    .ant-menu-inline .menu-icon {
        right: 40px;
        top: 8px;
    }
}


/* smaller phones */
@media only screen and (max-width: 420px) {
    .h1, h1 {
        font-size: 1.2rem;
    }
    .h3, h3 {
        font-size: 1.2rem;
    }
}