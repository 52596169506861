@import 'src/assets/styles/mixins.scss';

.breadcrumbs {
  padding: rem(10) rem(20);
  background-color: $white;
  border-bottom: 1px solid $gray-border;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.path {
  font-size: rem(18);
  color: $black;
}

.arrow {
  display: inline-block;
  margin: 0 rem(10);
  &:after {
    display: inline-block;
    content: '·';
  }
}
